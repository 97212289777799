import React from 'react'
import Link from 'next/link'
import styled from '@emotion/styled'

import colors from '@/src/styles/colors'
import FooterLinkBox from '@/src/components/blocks/FooterLinkBox'
import FooterMainContent from '@/src/components/blocks/FooterMainContent'
import { pageWidthLimit } from '@/src/styles/landingPageCommonStyles'
import { MediaQuery } from '@/src/utils/enums'

export default function LandingPageFooter() {
  return (
    <LandingPageFooterWrapper>
      <NonMobileFooterContentBox>
        <FooterMainContent />
        <FooterLinkBox />
      </NonMobileFooterContentBox>
      <MobileFooterContentBox>
        <FooterLinkBox />
        <FooterMainContent />
      </MobileFooterContentBox>
      <FooterTermsAndServicesLayout>
        <FooterTermsAndServices>
          <Copyrights>Copyright BUSINESS CANVAS 2022</Copyrights>
          <TermsAndServices>
            <Link href='/privacy'>Privacy</Link> /{' '}
            <Link href='/terms'>Terms and Conditions</Link>
          </TermsAndServices>
        </FooterTermsAndServices>
      </FooterTermsAndServicesLayout>
    </LandingPageFooterWrapper>
  )
}

const LandingPageFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${MediaQuery.PC} {
    margin-top: 320px;
  }
  ${MediaQuery.TABLET} {
    margin-top: 240px;
  }
  ${MediaQuery.MOBILE} {
    margin-top: 160px;
  }
  ${pageWidthLimit}
`

const FooterContentBox = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 32px;
`

const NonMobileFooterContentBox = styled(FooterContentBox)`
  justify-content: space-between;

  ${MediaQuery.MOBILE} {
    display: none;
  }
`

const MobileFooterContentBox = styled(FooterContentBox)`
  flex-direction: column;
  align-items: flex-start;
  gap: 120px;

  ${MediaQuery.NON_MOBILE} {
    display: none;
  }
`

const FooterTermsAndServicesLayout = styled.div`
  width: 100%;
  margin-top: 120px;

  ${MediaQuery.PC} {
    padding-bottom: 32px;
  }
  ${MediaQuery.TABLET} {
    padding-bottom: 32px;
  }
  ${MediaQuery.MOBILE} {
    padding-bottom: 24px;
  }
`

const FooterTermsAndServices = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${MediaQuery.MOBILE} {
    flex-direction: column;
    justify-content: center;
  }
`

const Copyrights = styled.div`
  color: ${colors.gray60()};
  font-size: 1.8rem;
  line-height: 1.5;
`

const TermsAndServices = styled.div`
  color: ${colors.gray11()};
  font-size: 1.8rem;
  line-height: 1.5;

  a {
    color: ${colors.gray11()};
  }
  a:visited {
    color: ${colors.gray11()};
  }
`
