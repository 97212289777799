import Link from 'next/link'
import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { TypedIcon } from 'typed-design-system'

import { UserLanguageContext } from '@/src/locale/LocaleContext'
import NewTabA from '@/src/components/elements/NewTabA'
import { MediaQuery } from '@/src/utils/enums'
import colors from '@/src/styles/colors'

export default function FooterLinkBox() {
  const {
    textWithLocale: {
      website: {
        footer: { linkItems },
      },
    },
  } = useContext(UserLanguageContext)
  return (
    <FooterLinkBoxWrapper>
      <LinkGroupBox>
        <LinkListColumn>
          <LinkListTopItem>{linkItems.Typed.content}</LinkListTopItem>
          <Link href={linkItems.Product.url}>
            <LinkListNormalItem>{linkItems.Product.content}</LinkListNormalItem>
          </Link>
          <Link href={linkItems.Features.url}>
            <LinkListNormalItem>
              {linkItems.Features.content}
            </LinkListNormalItem>
          </Link>
          <Link href={linkItems.Pricing.url}>
            <LinkListNormalItem>{linkItems.Pricing.content}</LinkListNormalItem>
          </Link>
          <NewTabA href={linkItems.Blog.url}>
            <LinkListNormalItem>{linkItems.Blog.content}</LinkListNormalItem>
          </NewTabA>
          <NewTabA href={linkItems.Brochure.url}>
            <LinkListNormalItem>
              {linkItems.Brochure.content}
              <TypedIcon icon='download_small' />
            </LinkListNormalItem>
          </NewTabA>
          <Link href={linkItems.Privacy.url}>
            <LinkListNormalItem>{linkItems.Privacy.content}</LinkListNormalItem>
          </Link>
        </LinkListColumn>
        <LinkListColumn>
          <LinkListTopItem>{linkItems.Experience.content}</LinkListTopItem>
          <Link href={linkItems.Founders.url}>
            <LinkListNormalItem>
              {linkItems.Founders.content}
            </LinkListNormalItem>
          </Link>
          <Link href={linkItems.Students.url}>
            <LinkListNormalItem>
              {linkItems.Students.content}
            </LinkListNormalItem>
          </Link>
          <Link href={linkItems.Investors.url}>
            <LinkListNormalItem>
              {linkItems.Investors.content}
            </LinkListNormalItem>
          </Link>
          <Link href={linkItems.Creators.url}>
            <LinkListNormalItem>
              {linkItems.Creators.content}
            </LinkListNormalItem>
          </Link>
        </LinkListColumn>
      </LinkGroupBox>
      <LinkGroupBox>
        <LinkListColumn>
          <LinkListTopItem>{linkItems.Resources.content}</LinkListTopItem>
          <NewTabA href={linkItems['Typed Academy'].url}>
            <LinkListNormalItem>
              {linkItems['Typed Academy'].content}
            </LinkListNormalItem>
          </NewTabA>
          <NewTabA href={linkItems['White Paper'].url}>
            <LinkListNormalItem>
              {linkItems['White Paper'].content}
              <TypedIcon icon='download_small' />
            </LinkListNormalItem>
          </NewTabA>
        </LinkListColumn>
        <LinkListColumn>
          <LinkListTopItem>Company</LinkListTopItem>
          <a href={linkItems.About.url}>
            <LinkListNormalItem>{linkItems.About.content}</LinkListNormalItem>
          </a>
          {linkItems.Careers && (
            <NewTabA href={linkItems.Careers.url}>
              <LinkListNormalItem>
                {linkItems.Careers.content}
              </LinkListNormalItem>
            </NewTabA>
          )}
          {linkItems.Notice && (
            <NewTabA href={linkItems.Notice.url}>
              <LinkListNormalItem>
                {linkItems.Notice.content}
              </LinkListNormalItem>
            </NewTabA>
          )}
          <NewTabA href={linkItems['Contact Us'].url}>
            <LinkListNormalItem>
              {linkItems['Contact Us'].content}
            </LinkListNormalItem>
          </NewTabA>
        </LinkListColumn>
      </LinkGroupBox>
    </FooterLinkBoxWrapper>
  )
}

const FooterLinkBoxWrapper = styled.div`
  display: flex;

  ${MediaQuery.PC} {
    width: 66%;
  }

  ${MediaQuery.TABLET} {
    width: 50%;
  }

  ${MediaQuery.MOBILE} {
    width: 100%;
  }

  ${MediaQuery.NON_PC} {
    flex-direction: column;
    gap: 80px;
  }
`

const LinkGroupBox = styled.div`
  display: flex;
  width: 100%;
`

const LinkListColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
`

const LinkListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${colors.gray20()};
  font-size: 1.6rem;
  line-height: 1.5;
`

const LinkListNormalItem = styled(LinkListItem)`
  cursor: pointer;

  svg,
  path {
    color: ${colors.gray20()} !important;
    font-size: 19px !important;
  }

  :hover {
    color: ${colors.formatDoc()};

    svg,
    path {
      fill: ${colors.formatDoc()} !important;
    }
  }
`

const LinkListTopItem = styled(LinkListItem)`
  font-weight: 700;
`
