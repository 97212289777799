// const BASE = 0
const UPPER_BASE = 1000
const ABOVE = 10
// const BELOW = -10

// index page
const LANDING_PAGE_HEADER = UPPER_BASE
const DROP_DOWN_BACK_DROP_LAYER = ABOVE * 2 + UPPER_BASE
const DROP_DOWN = ABOVE + DROP_DOWN_BACK_DROP_LAYER
const TOOLTIP = DROP_DOWN

export { LANDING_PAGE_HEADER, DROP_DOWN_BACK_DROP_LAYER, DROP_DOWN, TOOLTIP }
