import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { MediaQuery } from '@/src/utils/enums'
import shadows from '@/src/styles/shadows'
import colors from '@/src/styles/colors'

export const pageWidthLimit = css`
  ${MediaQuery.NON_MOBILE} {
    max-width: 1364px;
    padding: 0 32px;
  }
  ${MediaQuery.MOBILE} {
    max-width: 572px;
    padding: 0 24px;
  }
`

export const Heading1 = styled.h1`
  letter-spacing: -2%;
  line-height: 1.25;
  font-weight: 700;
  overflow-wrap: break-word;
  word-break: keep-all;

  ${MediaQuery.PC} {
    font-size: 72px;
  }
  ${MediaQuery.TABLET} {
    font-size: 56px;
  }
  ${MediaQuery.MOBILE} {
    font-size: 32px;
  }
`

export const Heading2 = styled.h1`
  letter-spacing: -2%;
  line-height: 1.25;
  font-weight: 700;
  overflow-wrap: break-word;
  word-break: keep-all;

  ${MediaQuery.PC} {
    font-size: 56px;
  }
  ${MediaQuery.TABLET} {
    font-size: 48px;
  }
  ${MediaQuery.MOBILE} {
    font-size: 28px;
  }
`

export const Heading3 = styled.h1`
  letter-spacing: -2%;
  line-height: 1.25;
  font-weight: 700;
  overflow-wrap: break-word;
  word-break: keep-all;

  ${MediaQuery.PC} {
    font-size: 32px;
  }
  ${MediaQuery.TABLET} {
    font-size: 28px;
  }
  ${MediaQuery.MOBILE} {
    font-size: 20px;
  }
`

export const Subtitle = styled.div`
  line-height: 1.5;
  font-weight: 500;
  overflow-wrap: break-word;
  word-break: keep-all;

  ${MediaQuery.PC} {
    font-size: 26px;
  }
  ${MediaQuery.TABLET} {
    font-size: 26px;
  }
  ${MediaQuery.MOBILE} {
    font-size: 18px;
  }
`

export const Body1 = styled.div`
  line-height: 1.6;
  font-weight: 300;
  overflow-wrap: break-word;
  word-break: keep-all;

  ${MediaQuery.PC} {
    font-size: 22px;
  }
  ${MediaQuery.TABLET} {
    font-size: 22px;
  }
  ${MediaQuery.MOBILE} {
    font-size: 16px;
  }
`

export const Body2 = styled.div`
  line-height: 1.6;
  font-weight: 300;
  overflow-wrap: break-word;
  word-break: keep-all;

  ${MediaQuery.PC} {
    font-size: 18px;
  }
  ${MediaQuery.TABLET} {
    font-size: 18px;
  }
  ${MediaQuery.MOBILE} {
    font-size: 14px;
  }
`

export const Caption = styled.div`
  overflow-wrap: break-word;
  word-break: keep-all;
  line-height: 1.35;
  font-size: 12px;
  font-weight: 400;
`

const CommonButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  font-weight: 500;
  cursor: pointer;
`

export const LandingPagePrimaryButton = styled(CommonButton)`
  background-color: ${colors.formatDoc()};
  color: ${colors.gray100()};
  box-shadow: ${shadows.blue15};

  :hover {
    background-color: #006bf3;
  }

  svg,
  path {
    fill: ${colors.gray100()} !important;
  }
`
export const LandingPageFlatButton = styled(CommonButton)`
  border: 1px solid ${colors.darker16()};
  background-color: ${colors.gray100()};
  color: ${colors.gray20()};
  box-shadow: ${shadows.light10};

  :hover {
    background-color: ${colors.darker4()};
  }

  svg,
  path {
    fill: ${colors.gray20()} !important;
  }
`

export const HeroImage = styled.img`
  width: 100%;
  border: 1px solid ${colors.darker8()};
  border-radius: 10px;
  filter: drop-shadow(0px 15px 50px rgba(0, 0, 0, 8%));

  ${MediaQuery.MOBILE} {
    filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 8%));
  }
`

export const InPC = styled.div`
  display: flex;
  width: 100%;

  ${MediaQuery.NON_PC} {
    display: none;
  }
`

export const OutOfPC = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MediaQuery.TABLET} {
    gap: 240px;
  }
  ${MediaQuery.MOBILE} {
    gap: 160px;
  }
  ${MediaQuery.PC} {
    display: none;
  }
`

export const ExampleImageWrapper = styled.div`
  position: relative;
  width: 100%;
`
export const ExampleImageShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(100px);
  opacity: 50%;
`
const exampleStyle = css`
  position: relative;
  width: 100%;
  border-radius: 20px;

  ${MediaQuery.MOBILE} {
    border-radius: 10px;
  }
`
export const ExampleImage = styled.img`
  ${exampleStyle}
`
export const ExampleVideo = styled.video`
  ${exampleStyle}
`
