import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { TypedIcon } from 'typed-design-system'

import colors from '@/src/styles/colors'
import shadows from '@/src/styles/shadows'
import {
  DROP_DOWN,
  LANDING_PAGE_HEADER,
  DROP_DOWN_BACK_DROP_LAYER,
} from '@/src/utils/zIndexRepository'

import useToggle from '@/src/hooks/useToggle'
import NewTabA from '@/src/components/elements/NewTabA'
import { UserLanguageContext } from '@/src/locale/LocaleContext'
import { MediaQuery } from '@/src/utils/enums'
import Link from 'next/link'

const TypedLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/fullTypedLogo.svg`
const GCPLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/GCPLogo.png`
const close = `${process.env.assetsCDNURL}/landingpage/close.svg`
const hamburger = `${process.env.assetsCDNURL}/landingpage/hamburger.svg`

export default function LandingPageHeader() {
  const [
    isServiceMenuDropDownOpen,
    ,
    closeServiceMenuDropDown,
    toggleServiceMenuDropDown,
  ] = useToggle(false)

  const {
    textWithLocale: {
      website: { header },
    },
  } = useContext(UserLanguageContext)

  return (
    <LandingPageHeaderWrapper>
      <LandingPageHeaderContent>
        <PCLogoBox>
          <Link href='/'>
            <TypedLogo src={TypedLogoSrc} alt='Typed' />
          </Link>
          <DividerLayout>
            <Divider />
          </DividerLayout>
          <GCPLogo src={GCPLogoSrc} alt='GCP' />
        </PCLogoBox>

        <NonPCLogoBox>
          <Link href='/'>
            <TypedLogo src={TypedLogoSrc} alt='Typed' />
          </Link>
        </NonPCLogoBox>

        <HeaderButtonBox>
          <PCServicePageButtonBox>
            <Link href={header.button.features.url}>
              <PCServicePageButtonLayout>
                <ServicePageButton>
                  {header.button.features.content}
                </ServicePageButton>
              </PCServicePageButtonLayout>
            </Link>
            <PCServicePageButtonLayout>
              <ServicePageButton>
                <ServicePageButtonContentLayout
                  className='button_icon_container'
                  gap={4}
                >
                  {header.button.experience.content}
                  <TypedIcon
                    icon='arrow_down_19'
                    style={{ fontSize: '13px' }}
                  />
                  <ServicePageButtonCover />
                </ServicePageButtonContentLayout>
                <ServicePageDropDownWrapper className='service_page_dropdown'>
                  {header.dropdown.experience.map((button) => {
                    return (
                      <Link key={button.content} href={button.url}>
                        <ServicePageButton
                          style={{ padding: '6px 53px 6px 20px' }}
                        >
                          {button.content}
                        </ServicePageButton>
                      </Link>
                    )
                  })}
                </ServicePageDropDownWrapper>
              </ServicePageButton>
            </PCServicePageButtonLayout>

            <Link href={header.button.pricing.url}>
              <PCServicePageButtonLayout>
                <ServicePageButton>
                  {header.button.pricing.content}
                </ServicePageButton>
              </PCServicePageButtonLayout>
            </Link>

            <NewTabA href={header.button.blog.url}>
              <PCServicePageButtonLayout>
                <ServicePageButton>
                  {header.button.blog.content}
                </ServicePageButton>
              </PCServicePageButtonLayout>
            </NewTabA>

            <NewTabA href={header.button['typed academy'].url}>
              <PCServicePageButtonLayout>
                <ServicePageButton>
                  {header.button['typed academy'].content}
                </ServicePageButton>
              </PCServicePageButtonLayout>
            </NewTabA>
          </PCServicePageButtonBox>

          <PCSignInButtonBox>
            <a href={header.button['log in'].url}>
              <LandingPageSimpleButton>
                {header.button['log in'].content}
              </LandingPageSimpleButton>
            </a>
            <a href={header.button['start typing'].url}>
              <LandingPagePrimaryButton>
                {header.button['start typing'].content}
              </LandingPagePrimaryButton>
            </a>
          </PCSignInButtonBox>

          <NonPCSignInButtonBox>
            <a href={header.button['log in'].url}>
              <LandingPagePrimaryButton>
                {header.button['log in'].content}
              </LandingPagePrimaryButton>
            </a>
            <ServiceMenuButton onClick={toggleServiceMenuDropDown}>
              {isServiceMenuDropDownOpen ? (
                <img src={close} alt='' />
              ) : (
                <img src={hamburger} alt='' />
              )}
            </ServiceMenuButton>
          </NonPCSignInButtonBox>
        </HeaderButtonBox>
      </LandingPageHeaderContent>

      {isServiceMenuDropDownOpen && (
        <>
          <ServiceMenuDropDownBackdrop onClick={closeServiceMenuDropDown} />
          <NonPCServiceMenuLayout>
            <ServiceMenuDropDown>
              <NonPCServiceButtonBox>
                <Link href={header.button.features.url}>
                  <ServicePageButton>
                    {header.button.features.content}
                  </ServicePageButton>
                </Link>
                <ServicePageButton>
                  {header.button.experience.content}
                </ServicePageButton>
                <ServiceMenuLowerButtonLayout>
                  {header.dropdown.experience.map((button) => {
                    return (
                      <Link key={button.content} href={button.url}>
                        <ServicePageButton>{button.content}</ServicePageButton>
                      </Link>
                    )
                  })}
                </ServiceMenuLowerButtonLayout>
                <Link href={header.button.pricing.url}>
                  <ServicePageButton>
                    {header.button.pricing.content}
                  </ServicePageButton>
                </Link>

                <NewTabA href={header.button.blog.url}>
                  <ServicePageButton>
                    {header.button.blog.content}
                  </ServicePageButton>
                </NewTabA>

                <NewTabA href={header.button['typed academy'].url}>
                  <ServicePageButton>
                    {header.button['typed academy'].content}
                  </ServicePageButton>
                </NewTabA>
              </NonPCServiceButtonBox>
              <NonPCServiceMenuSignInButtonLayout>
                <a href={header.button['log in'].url}>
                  <LandingPageSimpleButton>
                    {header.button['log in'].content}
                  </LandingPageSimpleButton>
                </a>
              </NonPCServiceMenuSignInButtonLayout>
            </ServiceMenuDropDown>
          </NonPCServiceMenuLayout>
        </>
      )}
    </LandingPageHeaderWrapper>
  )
}

const LandingPageHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${LANDING_PAGE_HEADER};
  width: 100%;
  height: 76px;
  padding: 20px 0;
  background-color: ${colors.gray100()};
`

const LandingPageHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  ${MediaQuery.NON_MOBILE} {
    padding: 0 32px;
  }
  ${MediaQuery.MOBILE} {
    padding: 0 24px;
  }
`

const LogoBox = styled.div`
  display: none;
  align-items: center;
  height: 100%;
`

const PCLogoBox = styled(LogoBox)`
  ${MediaQuery.PC} {
    display: flex;
  }
`

const NonPCLogoBox = styled(LogoBox)`
  ${MediaQuery.NON_PC} {
    display: flex;
  }
`

const HeaderButtonBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const TypedLogo = styled.img`
  width: 106px;
  cursor: pointer;
`

const DividerLayout = styled.div`
  height: 100%;
  margin: 0 8px 0 12px;
`

const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${colors.gray85()};
`

const GCPLogo = styled.img`
  width: 140px;
`

const PCServicePageButtonBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${MediaQuery.NON_PC} {
    display: none;
  }
`

const PCServicePageButtonLayout = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px;
`

const ServicePageButton = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  position: relative;
  height: 36px;
  padding: 6px 0;
  color: ${colors.gray11()};
  font-size: 1.6rem;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;

  svg,
  path {
    fill: ${colors.gray11()} !important;
  }

  :hover {
    color: ${colors.formatDoc()};

    & > .button_icon_container svg,
    & > .button_icon_container path {
      fill: ${colors.formatDoc()} !important;
    }

    .service_page_dropdown {
      display: flex;
    }
  }
`

const ServicePageButtonCover = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
`

const ServicePageButtonContentLayout = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${({ gap = 0 }) => gap}px;
  height: 100%;
`

const ServicePageDropDownWrapper = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 100%;
  left: -18px;
  z-index: ${DROP_DOWN};
  border: 1px solid ${colors.darker8()};
  border-radius: 8px;
  padding: 8px 0;
  box-shadow: ${shadows.light10};
  background-color: ${colors.gray100()};
`

const SignInButtonBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
`

const PCSignInButtonBox = styled(SignInButtonBox)`
  gap: 10px;

  ${MediaQuery.NON_PC} {
    display: none;
  }
`
const NonPCSignInButtonBox = styled(SignInButtonBox)`
  gap: 20px;

  ${MediaQuery.PC} {
    display: none;
  }
`

const LandingPageCommonButton = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 5px;
  padding: 7px 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;
`

const LandingPageSimpleButton = styled(LandingPageCommonButton)`
  border: 1px solid ${colors.darker16()};
  color: ${colors.gray20()};

  :hover {
    background-color: ${colors.darker4()};
  }
`

const LandingPagePrimaryButton = styled(LandingPageCommonButton)`
  background-color: ${colors.formatDoc()};
  color: ${colors.gray100()};

  :hover {
    background-color: #006bf3;
  }
`

const ServiceMenuButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    background-color: ${colors.darker4()};
  }

  img {
    width: 24px;
  }

  ${MediaQuery.PC} {
    display: none;
  }
`

const NonPCServiceMenuLayout = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: ${DROP_DOWN};
  width: 100%;

  ${MediaQuery.PC} {
    display: none;
  }
`

const ServiceMenuDropDown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px 0 40px 34px;
  background-color: ${colors.gray100()};
`

const ServiceMenuDropDownBackdrop = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: ${DROP_DOWN_BACK_DROP_LAYER};
  width: 100%;
  height: calc(100vh - 76px);
  background-color: ${colors.gray0(0.4)};
`

const NonPCServiceButtonBox = styled.div`
  display: flex;
  flex-direction: column;
`

const ServiceMenuLowerButtonLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
`

const NonPCServiceMenuSignInButtonLayout = styled.div`
  width: 140px;
`
