import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Dropdown, DropdownItem, TypedIcon } from 'typed-design-system'

import colors from '@/src/styles/colors'
import shadows from '@/src/styles/shadows'
import { DROP_DOWN_BACK_DROP_LAYER } from '@/src/utils/zIndexRepository'
import NewTabA from '@/src/components/elements/NewTabA'
import useToggle from '@/src/hooks/useToggle'
import { UserLanguageContext } from '@/src/locale/LocaleContext'
import { MediaQuery } from '@/src/utils/enums'

const TypedLogoSrc = `${process.env.assetsCDNURL}/landingpage/logos/fullTypedLogo.svg`

export default function FooterMainContent() {
  const [isDropdownOpen, openDropdown, closeDropdown] = useToggle(false)
  const dropDownContainerRef = useRef<HTMLDivElement>(null)
  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const dropdownContainer = dropDownContainerRef.current
    if (dropdownContainer) {
      setDropdownAnchor(dropdownContainer)
    }
  }, [])

  const {
    isKorean,
    changeLanguage,
    textWithLocale: {
      website: { footer },
    },
  } = useContext(UserLanguageContext)

  const handleEnglishBtnClick = () => {
    changeLanguage('en')
    closeDropdown()
  }

  const handleKoreanBtnClick = () => {
    changeLanguage('ko')
    closeDropdown()
  }

  return (
    <FooterMainContentWrapper>
      <LogoBox>
        <img src={TypedLogoSrc} style={{ marginBottom: '8px' }} />
        {footer.logoBoxTxt}
      </LogoBox>
      <LanguageSelectorBox ref={dropDownContainerRef} onClick={openDropdown}>
        {isKorean ? '한국어' : 'English'}
        <TypedIcon icon='arrow_down_19' style={{ fontSize: '13px' }} />
      </LanguageSelectorBox>
      {isDropdownOpen && dropdownAnchor && (
        <LanguageSelectionDropdown
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          anchorEl={dropdownAnchor}
          autoClose={false}
          dark={false}
          backDropZIndex={DROP_DOWN_BACK_DROP_LAYER}
          onClose={closeDropdown}
        >
          <LanguageSelectionDropdownItem
            isSelected={!isKorean}
            onClick={handleEnglishBtnClick}
          >
            <DropDownItemTxt>English</DropDownItemTxt>
          </LanguageSelectionDropdownItem>
          <LanguageSelectionDropdownItem
            isSelected={isKorean}
            onClick={handleKoreanBtnClick}
          >
            <DropDownItemTxt>한국어</DropDownItemTxt>
          </LanguageSelectionDropdownItem>
        </LanguageSelectionDropdown>
      )}
      <MobileAppLinkBox>
        {footer.mobileAppLinks.map((link) => (
          <NewTabA key={link.url} href={link.url}>
            <img src={link.imgSrc} />
          </NewTabA>
        ))}
      </MobileAppLinkBox>
      <SNSBox>
        {footer.SNSLinks.map((link) => (
          <NewTabA key={link.url} href={link.url}>
            <img src={link.imgSrc} />
          </NewTabA>
        ))}
      </SNSBox>
    </FooterMainContentWrapper>
  )
}

const FooterMainContentWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 32px;

  img {
    cursor: pointer;
  }

  ${MediaQuery.PC} {
    width: 34%;
  }
  ${MediaQuery.TABLET} {
    width: 50%;
  }
  ${MediaQuery.MOBILE} {
    width: 100%;
  }
`

const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.gray20()};
  font-size: 1.8rem;
  line-height: 1.5;

  img {
    width: 106px;
  }
`

const LanguageSelectorBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  height: 40px;
  border: 1px solid ${colors.darker16()};
  border-radius: 5px;
  padding: 9px 13px 10px 16px;
  color: ${colors.gray20()};
  font-size: 1.4rem;
  cursor: pointer;

  svg,
  path {
    fill: ${colors.gray20()};
  }
`

const LanguageSelectionDropdown = styled(Dropdown)`
  width: 280px;
  border: 1px solid ${colors.darker8()};
  border-radius: 5px;
  box-shadow: ${shadows.light10};
`

const LanguageSelectionDropdownItem = styled(DropdownItem)`
  font-weight: ${({ isSelected }) => (isSelected ? 500 : 400)};

  :hover {
    background-color: ${colors.darker4()};
  }
`

const DropDownItemTxt = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

const MobileAppLinkBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  img {
    height: 40px;
  }
`

const SNSBox = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    width: 30px;
  }
`
