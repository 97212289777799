import React from 'react'

interface NewTabAProps extends React.HTMLProps<HTMLAnchorElement> {
  children: React.ReactNode
}

export default function NewTabA({ children, ...props }: NewTabAProps) {
  return (
    <a
      style={{ cursor: 'pointer' }}
      target='_blank'
      rel='noopener noreferrer'
      {...props}
    >
      {children}
    </a>
  )
}
