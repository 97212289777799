import React from 'react'
import styled from '@emotion/styled'

import LandingPageHeader from '@/src/components/blocks/LandingPageHeader'
import LandingPageFooter from '@/src/components/blocks/LandingPageFooter'
import { MediaQuery, Selectors } from '@/src/utils/enums'

interface IndexPageLayoutProps {
  children?: React.ReactNode
}

export default function LandingPageLayout({ children }: IndexPageLayoutProps) {
  return (
    <LandingPageLayoutWrapper>
      <LandingPageHeader />
      <LandingPageWidthLimit>
        <LandingPageLayoutBody>{children}</LandingPageLayoutBody>
        <LandingPageFooter />
      </LandingPageWidthLimit>
    </LandingPageLayoutWrapper>
  )
}

const LandingPageLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
`

const LandingPageLayoutBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MediaQuery.PC} {
    gap: 320px;
    padding-top: 88px;
  }
  ${MediaQuery.TABLET} {
    gap: 240px;
    padding-top: 77px;
  }
  ${MediaQuery.MOBILE} {
    gap: 160px;
    padding-top: 60px;
  }
`

const LandingPageWidthLimit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${MediaQuery.NON_MOBILE} {
    max-width: 1364px;
    padding: 0 32px;
  }
  ${MediaQuery.MOBILE} {
    max-width: 572px;
    padding: 0 24px;
  }
`
